import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Ads from '../components/Ads';



const LocationPage = () => {
  const { locationId } = useParams();
  const [locationName, setLocationName] = useState("");
  const [date, setDate] = useState("");
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const adsData = [
    {image:'https://i.imgur.com/leyVsT7.jpg', alt:'Ad 1', link:'https://example1.com'},
    {image:'https://i.imgur.com/Bza8Nbj.jpg', alt:'Ad 2', link:'https://example2.com'},
    {image:'https://i.imgur.com/leyVsT7.jpg', alt:'Ad 1', link:'https://example1.com'},
    {image:'https://i.imgur.com/Bza8Nbj.jpg', alt:'Ad 2', link:'https://example2.com'},
    
  ];

  useEffect(() => {

    // Fetch locations.json from public/data
    fetch('/data/locations.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((locations) => {
        // Find the matching location
        const location = locations.find((loc) => loc.id === locationId);
        
        
        if (location) {
          setLocationName(location.name);
          setDate(location.updated);
        } else {
          setLocationName("Unknown Location");
        }
      })
      .catch((error) => {
        console.error("Error fetching location name:", error);
        setLocationName("Unknown Location");
      });
  
  
  fetch(`/data/${locationId}-items.json`)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      
      setItems(data);
    })
    .catch((error) => {
      console.error("Error fetching items:", error);
      setItems([]); // Set items to an empty array if the fetch fails
    });
}, [locationId]);

  // filter items based on search query
  const filteredItems = items.filter((item) => 
    item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.aisle.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <h1>{locationName}</h1>
      <p>Explore items at the this Costco location. <span id="red">Last updated: {date}</span><br/><br/> <em id="waste">Don't Waste Time, Find What You Need!</em></p>
      <Ads layout="grid" adsData={adsData} />
      {/* Add additional components or logic here */}
      
      <input
        className='search-bar'
        type="text"
        placeholder="Search items..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      {filteredItems.length > 0 ? (
        <div className="tablecontainer">
        <table style={{ width: "100%", borderCollapse: "collapse", backgroundColor: "#333", color: "#fff" }}>
  <thead>
    <tr>
      <th style={{ border: "1px solid #555", padding: "8px", color: "#90caf9" }}>Name</th>
      <th style={{ border: "1px solid #555", padding: "8px", color: "#90caf9" }}>Category</th>
      <th style={{ border: "1px solid #555", padding: "8px", color: "#90caf9" }}>Aisle</th>
    </tr>
  </thead>
  <tbody>
    {filteredItems.map((item) => (
      <tr key={item.id}>
        <td style={{ border: "1px solid #555", padding: "8px" }}>{item.name}</td>
        <td style={{ border: "1px solid #555", padding: "8px" }}>{item.category}</td>
        <td style={{ border: "1px solid #555", padding: "8px" }}>{item.aisle}</td>
      </tr>
    ))}
  </tbody>
</table>
</div>
      ) : (
        <p style={{ marginTop: "20px", color: "red", fontSize: "20px" }}>Item Not Found - Item may still be in the store but location not recorded yet.</p>
      )}
    </div>
  );
};

export default LocationPage;
