import React from 'react';

const Ads = ({ layout, adsData }) => {
  return (
    <div style={styles.container}>
      {adsData.map((ad, index) => (
        <a
          key={index}
          href={ad.link}
          target="_blank"
          rel="noopener noreferrer"
          style={layout === "grid" ? styles.gridItem : styles.singleItem}
        >
          <img src={ad.image} alt={ad.alt} style={styles.image} />
        </a>
      ))}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '10px',
    marginTop: '20px',
  },
  gridItem: {
    flex: '1 1 calc(33.33% - 10px)', // Adjusts for a 3x2 grid
    maxWidth: '150px',
    textAlign: 'center',
    border: '1px solid #ccc',
    borderRadius: '5px',
    overflow: 'hidden',
  },
  singleItem: {
    width: '100%',
    textAlign: 'center',
    border: '1px solid #ccc',
    borderRadius: '5px',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: 'auto',
  },
};

export default Ads;
