import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import LocationPage from './components/LocationPage';
import './App.css';
import Header from './components/Header';
import { Analytics } from "@vercel/analytics/react"
import About from './components/About';

const App = () => {
  return (
    <Router>
      <Header/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/location/:locationId" element={<LocationPage/>}/>
        <Route path="/about" element={<About/>}/>
      </Routes>
      <Analytics/>
    </Router>
    
  );
};

export default App;
