import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header style={styles.header}>
      {/* Logo on the left */}
      <Link to="/" style={styles.logoLink}>
        <img id="logo" src="/finder-logo.png" alt="Logo" style={styles.logo} />
      </Link>
      <nav style={styles.nav}>
        <Link to="/" style={styles.navLink}>Home</Link>
        <Link to="/about" style={styles.navLink}>About</Link>
      </nav>

      {/* Centered h1 */}
      

      <h1 id="header-title" style={styles.title}>Welcome To Aisle Tracker</h1>
      
      
    </header>
  );
};

const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Distributes items evenly with space
    padding: '10px 20px',
    borderBottom: '5px solid #444',
    position: 'relative', // Ensures h1 can be centered
  },
  logoLink: {
    textDecoration: 'none',
    marginRight: 'auto', // Align logo to the far left
  },
  logo: {
    height: '50px',
    width: 'auto',
  },
  title: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)', // Centers the h1 horizontally
    fontSize: '24px',
    color: '#000',
    margin: 0,
  },
};

export default Header;
