import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Ads from '../components/Ads';


const Home = () => {
  const [locations, setLocations] = useState([]);
  const adsData = [
    {image:'https://i.imgur.com/leyVsT7.jpg', alt:'Ad 1', link:'https://example1.com'},
    {image:'https://i.imgur.com/Bza8Nbj.jpg', alt:'Ad 2', link:'https://example2.com'},
    {image:'https://i.imgur.com/leyVsT7.jpg', alt:'Ad 1', link:'https://example1.com'},
    {image:'https://i.imgur.com/Bza8Nbj.jpg', alt:'Ad 2', link:'https://example2.com'},
    {image:'https://i.imgur.com/leyVsT7.jpg', alt:'Ad 1', link:'https://example1.com'},
    {image:'https://i.imgur.com/Bza8Nbj.jpg', alt:'Ad 2', link:'https://example2.com'},
    {image:'https://i.imgur.com/leyVsT7.jpg', alt:'Ad 1', link:'https://example1.com'},
    {image:'https://i.imgur.com/Bza8Nbj.jpg', alt:'Ad 2', link:'https://example2.com'},
    
  ];

  useEffect(() => {
    // Fetch locations from the public folder
    fetch('/data/locations.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => 
        setLocations(data))
      .catch((error) => {
        console.error("Error fetching locations:", error);
      });
  }, []);

  return (
    <div>
      
      <p>Select your Costco location to find items:</p>
      <ul style={{ display: 'flex', flexWrap: 'wrap', padding: 0, listStyle: 'none' }}>
  {locations.map((location) => (
    <li key={location.id} style={{ flex: '1 1 calc(50% - 20px)', margin: '10px' }}>
      <Link to={`/location/${location.id}`}>{location.name}</Link>
    </li>
  ))}
</ul>
      <Ads layout="grid" adsData={adsData} />
    </div>
  );
};

export default Home;
